import * as ActionCable from 'actioncable';

export default class CableService {
  constructor(domain, protocol) {
    this.domain = domain || 'localhost:3000';
    this.protocol = protocol || 'http';
    this.initializeCable = this.initializeCable.bind(this);
    this.initializeCable();
  }

  initializeCable() {
    var wsprotocol = (this.protocol == 'https:' ? 'wss' : 'ws');
    this.cable = ActionCable.createConsumer(
      `${wsprotocol}://${this.domain}/cable`
    );
  }
}
